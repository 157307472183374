define("happyfox-bi/application/theme-picker/theme-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bl3x4qJY",
    "block": "{\"symbols\":[\"@theme\"],\"statements\":[[11,\"section\"],[24,0,\"theme-option-container\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[35,1]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"theme-svgs-container\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"name\"]]],[[\"class\"],[[30,[36,4],[\"theme-item-icon\",[30,[36,3],[[32,0,[\"isSelected\"]],\"is-selected\"],null]],null]]]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isSelected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"circled-tick\"],[[\"class\"],[\"circled-tick-icon\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"theme-name\"],[12],[1,[30,[36,5],[[32,1,[\"name\"]]],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"selectTheme\",\"on\",\"if\",\"concat-strings\",\"t\"]}",
    "meta": {
      "moduleName": "happyfox-bi/application/theme-picker/theme-option/template.hbs"
    }
  });

  _exports.default = _default;
});
define("happyfox-bi/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    profileService: Ember.inject.service('profile'),
    dataService: Ember.inject.service('data'),
    applicationService: Ember.inject.service('application'),
    toastMessageService: Ember.inject.service('toast-message'),
    isUserAuthenticated: Ember.computed.reads('profileService.isUserAuthenticated'),
    isSidePaneOpen: Ember.computed.reads('applicationService.isSidePaneOpen'),
    canShowMySettings: false,
    canShowAccountSwitcherModal: false,
    isLoading: false,
    canShowThemePicker: false,
    topBarPopOverIsOpen: false,
    routerService: Ember.inject.service('router'),
    showSidePane: Ember.computed('routerService.currentRouteName', {
      get: function get() {
        var currentRouteName = this.get('routerService.currentRouteName');
        var firstSegment = currentRouteName.split('.')[0];
        return firstSegment === 'reports';
      }
    }),
    showManageSidebar: Ember.computed('routerService.currentRouteName', {
      get: function get() {
        var currentRouteName = this.get('routerService.currentRouteName');
        var firstSegment = currentRouteName.split('.')[0];
        var routesWithManageSidebar = ["manage", "report-subscriptions", "business-hours", "report-templates"];
        return routesWithManageSidebar.includes(firstSegment);
      }
    }),
    actions: {
      logout: function logout(loginRouteQueryParams) {
        var _this = this;

        this.set('isLoading', true);
        this.get('dataService').staffLogout().then(function () {
          _this.get('profileService').set('isUserAuthenticated', false);

          _this.transitionToRoute('login', {
            queryParams: loginRouteQueryParams
          });
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      goToReportDetailAction: function goToReportDetailAction(reportId) {
        this.transitionToRoute('reports.details', reportId);
      },
      refresh: function refresh() {
        this.send('refreshApplication');
      },
      toggleShowThemePicker: function toggleShowThemePicker() {
        this.toggleProperty('canShowThemePicker');
        this.set('topBarPopOverIsOpen', false);
      }
    }
  });

  _exports.default = _default;
});
define("happyfox-bi/manage/account-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2GYjABRK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"list-body content_outlet_main\"],[12],[2,\"\\n\"],[6,[37,8],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[19,\"templates/partials/loading\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"section\"],[14,0,\"manage-account-settings-form-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"manage-account-settings-form_title u-vertically-centered-container\"],[12],[1,[30,[36,3],[\"account-settings\"],null]],[13],[2,\"\\n      \"],[10,\"form\"],[14,0,\"manage-account-settings-form\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"field\"],[\"mod-field-half\",[35,0,[\"model\",\"timezone\"]]]]]],[2,\"\\n\\n\"],[6,[37,8],[[35,7,[\"manageAccountSettings\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"manage-account-settings-form_footer\"],[12],[2,\"\\n            \"],[1,[30,[36,5],null,[[\"class\",\"text\",\"inProgress\",\"inProgressText\",\"onClick\",\"isDirty\",\"disabled\"],[\"hf-primary-action\",[30,[36,3],[\"save\"],null],[35,4],[30,[36,3],[\"saving\"],null],[30,[36,2],[[32,0],\"saveAccountSettings\"],null],[35,0,[\"isDirty\"]],[30,[36,1],[[35,0,[\"isDirty\"]]],null]]]]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":true,\"upvars\":[\"form\",\"not\",\"action\",\"t\",\"isSaveInProgress\",\"async-button\",\"form-field\",\"featuresService\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/account-settings/template.hbs"
    }
  });

  _exports.default = _default;
});
define("happyfox-bi/components/widget-tile/raw-data/component", ["exports", "ember-light-table", "happyfox-bi/mixins/table", "happyfox-bi/utilities/array", "happyfox-bi/utilities/time", "happyfox-bi/components/base-chart/constants", "happyfox-bi/utilities/number", "happyfox-bi/constants"], function (_exports, _emberLightTable, _table, _array, _time, _constants, _number, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_table.default, {
    classNames: ['chart', 'data-table'],
    dataService: Ember.inject.service('data'),
    visualization: null,
    isFetchingData: null,
    isPreview: false,
    table: null,
    data: null,
    filters: null,
    isRequestFailure: false,
    settings: Ember.computed.reads('visualization.settings'),
    selectedDateFormat: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var visualizationId = this.get('visualization.id');
      var visualizationSettings = this.get('visualization.settings');
      var filters = this.get('filters');
      var dataPromise;

      if (this.get('isPreview')) {
        dataPromise = this.get('dataService').getPreviewRawData(visualizationSettings);
      } else {
        dataPromise = this.get('dataService').getRawData(visualizationId, filters);
      }

      var query = this.get('settings.query');
      var dataSourceId = query.get('dataSource');
      var dataSetId = query.get('dataSet');
      var dataSetFromStore = this.get('dataService').peekDataSet(dataSetId);
      var dataSetPromise;

      if (dataSetFromStore) {
        dataSetPromise = Ember.RSVP.resolve(dataSetFromStore);
      } else {
        dataSetPromise = this.get('dataService').getDataSet(dataSourceId, dataSetId);
      }

      var dataSourceFromStore = this.get('dataService').peekDataSource(dataSourceId);
      var dataSourcePromise;

      if (dataSourceFromStore) {
        dataSourcePromise = Ember.RSVP.resolve(dataSourceFromStore);
      } else {
        dataSourcePromise = this.get('dataService').getDataSource(dataSourceId);
      }

      var promises = {
        dataPromise: dataPromise,
        dataSetPromise: dataSetPromise,
        dataSourcePromise: dataSourcePromise
      };
      this.set('isFetchingData', true);
      return Ember.RSVP.hash(promises).then(function (hashData) {
        _this.set('isRequestFailure', false);

        var data = hashData.dataPromise;

        if (data.length) {
          var dataSet = hashData.dataSetPromise;
          var dataSource = hashData.dataSourcePromise;
          var rawDataFieldsMeta = dataSet.get('settings.rawDataFields') || Ember.A();

          var rawDataFields = _this.generateRawDataFields(rawDataFieldsMeta, dataSet.get('fields')); // Fallback when raw data fields are not configured on dataset


          if (!rawDataFields.length) {
            var idField = Ember.Object.create({
              name: "".concat(dataSet.get('name'), " ID")
            });
            rawDataFields = Ember.A([idField]);
          }

          var visualizationFields = _this.get('settings.fields');

          var orderedColumnsMeta = _this.getOrderedColumnsMeta(visualizationFields, rawDataFields, Object.keys(data[0]));

          var columns = orderedColumnsMeta.map(function (meta) {
            var column = {
              valuePath: meta.key,
              label: meta.label,
              sortable: true,
              resizable: true,
              minResizeWidth: 100,
              component: 'widget-tile/raw-data/column'
            };
            var field = visualizationFields.findBy('name', meta.key);

            if (field && _constants2.NUMBER_TYPE_FIELDS.includes(field.get('dataType'))) {
              column.cellClassNames = 'align-right';
            }

            return column;
          });
          var rawDataFieldsNames = rawDataFields.mapBy('name');
          rawDataFieldsNames.forEach(function (fieldName) {
            var column = columns.findBy('valuePath', fieldName);

            if (column) {
              column.label = fieldName.replace('rdf_', '');
            }
          });
          var allFields = Ember.A().addObjects(visualizationFields).addObjects(rawDataFields);
          var allFieldsUsed = orderedColumnsMeta.map(function (_ref) {
            var key = _ref.key;
            return allFields.findBy('name', key);
          });
          var fieldsWithUrl = allFieldsUsed.filterBy('urlMeta');
          var columnNamesWithUrl = fieldsWithUrl.mapBy('name'); // Showing only upto 50 records to prevent browser crash

          data = data.slice(0, 50);

          _this.set('data', _this.processData(data, dataSource, dataSet, fieldsWithUrl));

          columnNamesWithUrl.forEach(function (columnName) {
            var column = columns.findBy('valuePath', columnName);
            column.cellComponent = 'widget-tile/raw-data/link';
            column.cellClassNames = 'mod-link';
          });

          var table = _emberLightTable.default.create({
            columns: columns,
            rows: data
          });

          var rows = table.rows;

          _this.set('rows', rows);

          _this.set('table', table);
        }
      }).catch(function () {
        _this.set('isRequestFailure', true);
      }).finally(function () {
        _this.set('isFetchingData', false);
      });
    },
    generateRawDataFields: function generateRawDataFields(rawDataFieldsMeta, fields) {
      return rawDataFieldsMeta.map(function (fieldMeta) {
        var field = fields.findBy('id', fieldMeta.get('datasetField'));
        var rawDataField;

        if (fieldMeta.get('parentDataset')) {
          rawDataField = Ember.Object.create({
            'datasetField': fieldMeta.get('datasetField'),
            'name': 'rdf_' + fieldMeta.get('name'),
            'type': 'dataset_field',
            'urlMeta': fieldMeta.get('urlMeta')
          });
        } else {
          rawDataField = Ember.Object.create({
            'datasetField': field.get('id'),
            'name': 'rdf_' + field.get('name'),
            'type': 'dataset_field',
            'urlMeta': field.get('urlMeta')
          });
        }

        return rawDataField;
      });
    },
    getOrderedColumnsMeta: function getOrderedColumnsMeta(visualizationFields, rawDataFields, dataKeys) {
      var orderedFields = Ember.A();
      orderedFields.addObject(rawDataFields[0]); // Implicit assumption that first raw data field is always id

      var dateRangeFieldName = this.get('settings.query.dateRangeField');

      if (dateRangeFieldName) {
        orderedFields.addObject(visualizationFields.findBy('name', dateRangeFieldName));
      }

      Ember.A().addObjects(visualizationFields).addObjects(rawDataFields).forEach(function (field) {
        var datasetFieldId = Number(field.get('datasetField'));
        var isPresentInOrderedFields = orderedFields.any(function (orderedField) {
          return datasetFieldId === Number(orderedField.get('datasetField'));
        });

        if (!isPresentInOrderedFields) {
          orderedFields.addObject(field);
        }
      });
      orderedFields = orderedFields.filter(function (field) {
        return dataKeys.includes(field.get('name'));
      });
      return orderedFields.map(function (field) {
        return {
          key: field.get('name'),
          label: field.get('displayName') || field.get('name')
        };
      });
    },
    processData: function processData(data, dataSource, dataSet, fieldsWithUrl) {
      var _this2 = this;

      var dateRangeFieldName = this.get('settings.query.dateRangeField');
      var fields = this.settings.fields;
      var dateAndTimestampFields = Ember.A();
      var timestampDataFields = fields.filterBy('dataType', 'timestamp').filter(function (field) {
        return _constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(field.get('format'));
      });
      timestampDataFields = timestampDataFields.rejectBy('name', dateRangeFieldName);
      var dateFields = fields.filterBy('dataType', 'date').filter(function (field) {
        return _constants.TIMESTAMP_BASED_FIELD_FORMATS.includes(field.get('format'));
      });
      dateAndTimestampFields.addObjects(dateFields).addObjects(timestampDataFields);
      var currencyFields = fields.filter(function (field) {
        var isCurrencyField = field.get('dataType') === 'currency';
        var canFormatAsCurrency = _constants.FIELD_TYPES_FORMATTED_AS_CURRENCY.includes(field.get('dataType')) && field.get('format') == 'currency';
        return isCurrencyField || canFormatAsCurrency;
      });
      var timeFields = this.get('settings.fields').filterBy('dataType', 'time'); // Data sent from BE is in user timezone, so we should use moment.utc for displaying correct value in FE

      data.forEach(function (dataItem) {
        if (!(_this2.selectedDateFormat && _constants.NON_DATE_API_RESPONSE_FORMATS.includes(_this2.selectedDateFormat.id))) {
          // Format date range field value   
          dataItem[dateRangeFieldName] = moment.utc(dataItem[dateRangeFieldName]).format('MMM DD YYYY, h:mm A'); // Format timestamp field values

          dateAndTimestampFields.forEach(function (field) {
            var name = field.get('name');
            var value = dataItem[name];

            if (Ember.isPresent(value)) {
              var format = field.get('format');

              if (format === 'month_with_year') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM YYYY');
              } else if (format === 'date') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY');
              } else if (format === 'hour_with_date') {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY, h A');
              } else if (format === 'year') {
                dataItem[name] = moment.utc(dataItem[name]).format('YYYY');
              } else if (format === 'week_with_year') {
                dataItem[name] = moment.utc(dataItem[name]).format('wo [week of] YYYY');
              } else if (format === 'week_with_month') {
                var startDate = moment.utc(dataItem[name]);
                var endDate = moment.utc(startDate).add(6, 'days');
                dataItem[name] = "".concat(startDate.format('MMM DD YYYY'), " to ").concat(endDate.format('MMM DD YYYY'));
              } else {
                dataItem[name] = moment.utc(dataItem[name]).format('MMM DD YYYY, h:mm A');
              }
            }
          });
        } // Format time fields


        timeFields.forEach(function (field) {
          var fieldName = field.name;
          var value = dataItem[fieldName];

          if (Ember.isPresent(value)) {
            var timeUnit = field.timeUnit || 'hours';
            var options = {};

            if (timeUnit !== 'seconds') {
              options.precision = 2;
              options.format = (0, _time.getLongHandDurationFormatByUnit)(timeUnit);
            }

            dataItem[fieldName] = (0, _time.humanizeDuration)(value, timeUnit, options);
          }
        }); // Format currency fields

        currencyFields.forEach(function (field) {
          var name = field.get('name');
          var value = dataItem[name];
          var currencyUnit = field.currencyUnit,
              currencyFormat = field.currencyFormat;
          dataItem[name] = (0, _number.getCurrencyValue)(value, currencyUnit, currencyFormat);
        }); // Generate urls

        fieldsWithUrl.forEach(function (field) {
          var columnName = field.get('name');
          var urlMeta = field.get('urlMeta');
          var dataItemValue = dataItem[columnName];
          var variableTokens = urlMeta.format.match(/{{.+?}}/g);
          var url = urlMeta.format;
          variableTokens.forEach(function (token) {
            var replacementValue = dataItem[token.substring(2, token.length - 2)];
            url = url.replace(token, replacementValue);
          });
          dataItem[columnName] = {
            value: dataItemValue,
            link: url
          };
        });
      });
      return data;
    },
    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          var sortFields = [];
          var rows = this.get('rows').toArray();
          var sortedRows;

          if (column) {
            sortFields.push({
              field: column.get('valuePath'),
              order: column.ascending ? 'asc' : 'desc'
            });
          }

          sortedRows = (0, _array.sortArrayOfObjects)(rows, sortFields);
          this.get('table').setRows(sortedRows);
        }
      }
    }
  });

  _exports.default = _default;
});
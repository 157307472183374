define("happyfox-bi/application/theme-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ESW3OlXy",
    "block": "{\"symbols\":[\"themeCategory\",\"themeItem\",\"@canShowThemePicker\"],\"statements\":[[6,[37,6],[[32,3]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"modifierClass\",\"isOpen\"],[\"slide-in-form\",[32,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"section\"],[14,0,\"theme-picker_main\"],[12],[2,\"\\n      \"],[10,\"header\"],[14,0,\"theme-picker_main_header\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"theme-picker_main_header_title font-semi-bold\"],[12],[1,[30,[36,3],[\"choose-theme\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"section\"],[14,0,\"theme-options-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"theme-category-container\"],[12],[2,\"\\n            \"],[10,\"h2\"],[14,0,\"theme-category-title\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"themes-list-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"themes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"application/theme-picker/theme-option\",[],[[\"@theme\",\"@onSelectTheme\",\"@selectedTheme\"],[[32,2],[30,[36,0],[[32,0],\"selectTheme\"],null],[32,0,[\"currentTheme\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\",\"t\",\"themeOptions\",\"slide-in\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/application/theme-picker/template.hbs"
    }
  });

  _exports.default = _default;
});
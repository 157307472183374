define("happyfox-bi/application/top-bar/component", ["exports", "happyfox-bi/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['app-top-bar', 'top-bar'],
    // class .top-bar is used in popover position/dimension calculation
    classNameBindings: ['isDesktopApp'],
    attributeBindings: ['data-pop-over-selector'],
    dataService: Ember.inject.service('data'),
    profileService: Ember.inject.service('profile'),
    routerService: Ember.inject.service('router'),
    profile: Ember.computed.reads('profileService.profile'),
    featuresService: Ember.inject.service('features'),
    isAppsEnabled: Ember.computed.reads('featuresService.manageApps'),
    isDatasourcesEnabled: Ember.computed.reads('featuresService.manageDataSources'),
    isReportTemplatesEnabled: Ember.computed.reads('featuresService.createReportTemplates'),
    isReportSubscriptionsEnabled: Ember.computed.reads('featuresService.reportSubscriptions'),
    applicationService: Ember.inject.service('application'),
    fullscreenService: Ember.inject.service('fullscreen'),
    isCurrentlyFullscreen: Ember.computed.reads('fullscreenService.isCurrentlyFullscreen'),
    isDesktopApp: _constants.IS_DESKTOP_APP,
    isThemeSelectionOpen: false,
    username: null,
    canShowMySettings: false,
    canShowAccountSwitcherModal: false,
    currentTheme: Ember.computed('profile.themePreferences', {
      get: function get() {
        var _this$get = this.get('profile'),
            themePreferences = _this$get.themePreferences;

        return themePreferences;
      }
    }),
    canShowModuleSwitcher: Ember.computed('isReportTemplatesEnabled', 'isReportSubscriptionsEnabled', 'isDatasourcesEnabled', 'isAppsEnabled', {
      get: function get() {
        var isReportTemplatesEnabled = this.isReportTemplatesEnabled,
            isReportSubscriptionsEnabled = this.isReportSubscriptionsEnabled,
            isDatasourcesEnabled = this.isDatasourcesEnabled,
            isAppsEnabled = this.isAppsEnabled;
        return isReportTemplatesEnabled || isReportSubscriptionsEnabled || isDatasourcesEnabled || isAppsEnabled;
      }
    }),
    canShowAccountSwitcher: Ember.computed('profile.userAccounts.[]', {
      get: function get() {
        var profile = this.profile;
        return profile && profile.userAccounts.length > 1;
      }
    }),
    pageTitle: Ember.computed('routerService.currentRouteName', function () {
      var currentRouteName = this.get('routerService.currentRouteName');
      var firstSegment = currentRouteName.split('.')[0];

      if (['apps', 'reports', 'data-sources'].includes(firstSegment)) {
        return firstSegment;
      } else if (currentRouteName.startsWith('visualizations')) {
        return "reports";
      } else {
        return 'manage';
      }
    }),
    actions: {
      changeTheme: function changeTheme(theme) {
        var _this = this;

        var app = theme.app,
            charts = theme.charts;
        var requestData = {
          "theme_preferences": {
            app: app,
            charts: charts
          }
        };
        this.dataService.updateProfile(requestData).then(function () {
          _this.onRefresh();
        }).finally(function () {
          _this.set('isThemeSelectionOpen', false);
        });
      },
      toggleMySettings: function toggleMySettings() {
        this.toggleProperty('canShowMySettings');
      },
      toggleAccountSwitcher: function toggleAccountSwitcher() {
        this.toggleProperty('canShowAccountSwitcherModal');
      },
      logoutStaff: function logoutStaff() {
        this.logout();
      },
      reload: function reload() {
        if (this.get('isDesktopApp')) {
          window.location.reload();
        }
      },
      showThemePicker: function showThemePicker() {
        this.toggleShowThemePicker();
      }
    }
  });

  _exports.default = _default;
});